footer * {
  font-family: "Tertius Sans Normal", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--white);
}

footer {
  background: var(--light-beige);
  border-top: 1px solid var(--white);
  height: 11rem;
  padding-top: 1.5rem;
  padding-bottom: 4rem;
  margin: 1.5rem 1.5rem 0 1.5rem;
}

footer h2 {
  font-family: "Tertius Sans Bold", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: clamp(1rem, -2.3333rem + 11.1111vw, 3rem);
  color: var(--white);
  margin-bottom: 2.5rem;
}

.footer-container {
  background: var(--light-beige);
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.email {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  width: 50%;
  height: 2.5rem;
}

.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.border-black {
  --tw-border-opacity: 1;
  border-color: var(--white);
  border-color: rgba(225, 225, 225, var(--tw-border-opacity));
}

.border {
  border-width: 1px;
}

.newsletter-email {
  background: var(--light-beige);
}

.btn-submit {
  background: var(--white);
  color: var(--light-beige);
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.sites li {
  transition: all ease 0.5s;
}

.sites:hover li {
  opacity: 1;
}

.sites:hover li:not(:hover) {
  opacity: 0.3;
}

.social li {
  transition: all ease 0.5s;
}

.social:hover li {
  opacity: 1;
}

.social:hover li:not(:hover) {
  opacity: 0.3;
}
