.soon-txt {
    font-size: clamp(1rem, 1rem + 6.8966vw, 3rem) !important;
}

.coming-soon-img {
    width: 600px !important;
}

.z-150 {
    z-index: 150 !important;
}