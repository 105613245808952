#about {
  background: var(--light-beige);
}

.img-wrapper {
  display: flex;
  overflow: hidden;
  overflow: hidden;
  position: relative;
  align-items: center;
  justify-content: center;
  background: var(--light-beige);
}

.about-img {
  will-change: transform;
  transform: scale(1.25);
  clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
}

.about-text {
  margin: auto;
}

.about-text span {
  margin: 0 5px;
}

.about-text p {
  font-size: clamp(1rem, 1rem + 6.8966vw, 1.5rem);
  font-family: "Tertius Sans Normal", Helvetica, Arial, sans-serif;
  font-weight: 700;
}

.about-blob {
  font-family: "Tertius Sans Bold", Helvetica, Arial, sans-serif;
  font-size: clamp(2rem, -2.3333rem + 11.1111vw, 5rem);
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
  color: var(--white);
}

#about h3 {
  font-family: "Tertius Sans Normal", Helvetica, Arial, sans-serif;
}

.about-inner p {
  font-size: 16px;
  color: var(--white);
}

.line {
  border-right: none !important;
}

@media (max-width: 991.98px) {
  .about-inner {
    text-align: center;
  }
}

@media (max-width: 575.98px) {
  .about-image {
    width: 100%;
  }

  .about-blob {
    margin-right: 0;
    font-size: 2rem !important;
  }
}