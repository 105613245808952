.room-wrapper {
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.h-screen {
    height: 100vh;
}

.min-w-full {
    min-width: 100%;
}

.min-h-full {
    min-height: 100%;
}

.ease-in-out {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-200 {
    transition-duration: .2s;
}

.duration-500 {
    transition-duration: .5s;
}

.duration-150 {
    transition-duration: .15s;
}

.transition-opacity {
    transition-property: opacity;
    /* transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .15s; */
}

.transition {
    /* transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter; */
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    /* transition-timing-function: cubic-bezier(.4, 0, .2, 1); */
    /* transition-duration: .15s; */
}

.text-base {
    font-size: 1rem;
    line-height: 1.5rem;
}

.py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
}

.px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}

.bg-white {
    --tw-bg-opacity: 1;
    background-color: var(--bs-white);
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.rounded-full {
    border-radius: 9999px;
}

.rounded-\[2px_0_0\] {
    border-radius: 2px 0 0;
}

.rotate-45 {
    --tw-rotate: 45deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(45deg) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-1\/2 {
    --tw-translate-x: -50%;
    /* transform: translate(-50%, var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)); */
    /* transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)); */
}

.blur-xl {
    --tw-blur: blur(24px);
    /* filter: blur(24px) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow); */
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.h-full {
    height: 100%;
}

.z-10 {
    z-index: 10;
}

.z-20 {
    z-index: 20;
}

.z-30 {
    z-index: 30;
}

.absolute {
    position: absolute;
}

.pointer-events-none {
    pointer-events: none;
}

img,
video {
    max-width: 100%;
    /* height: auto; */
}

.font-bold {
    font-weight: 700;
}

.border {
    border-width: 1px;
}

.h-6 {
    height: 1.5rem;
}

.w-2 {
    width: .5rem;
}

.h-2 {
    height: .5rem;
}

.w-\[11px\] {
    width: 11px;
}

.h-\[11px\] {
    height: 11px;
}

.top-full {
    top: 100%;
}

.left-1\/2 {
    left: 50%;
}

.-top-4 {
    top: -1rem;
}

.-left-4 {
    left: -1rem;
}

.-left-5 {
    left: 5rem;
}

.-top-1 {
    top: -.25rem;
}

.p-4 {
    padding: 1rem !important;
}

.-top-10 {
    top: 10rem;
}

.bg-white\/5 {
    background-color: #ffffff45;
}

.border-white {
    --tw-border-opacity: 1;
    border-color: var(--bs-white);
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

.border {
    border-width: 1px;
}

.-top-\[40px\] {
    top: -40px;
}

.-right-\[40px\] {
    right: -40px;
}

.-left-\[40px\] {
    left: -40px;
}

.-bottom-\[40px\] {
    bottom: -40px;
}

.absolute {
    position: absolute;
}

.pointer-events-none {
    pointer-events: none;
}

.before\:shadow-\[0_0_10px_5px_\#00000040\]:before {
    content: "";
    --tw-shadow: 0 0 10px 5px rgba(0, 0, 0, .25098);
    --tw-shadow-colored: 0 0 10px 5px var(--tw-shadow-color);
    box-shadow: 0 0 #0000, 0 0 #0000, 0 0 10px 5px #00000040;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow)
}

.shadow-\[0_0_10px_\#0000001a\] {
    --tw-shadow: 0 0 10px rgba(0, 0, 0, .10196);
    --tw-shadow-colored: 0 0 10px var(--tw-shadow-color);
    box-shadow: 0 0 #0000, 0 0 #0000, 0 0 10px #0000001a;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.before\:bg-white:before {
    content: "";
    --tw-bg-opacity: 1;
    background-color: var(--bs-white);
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
}

.before\:absolute:before {
    content: "";
    position: absolute
}

.before\:-left-4:before {
    content: "";
    left: -1rem
}

.before\:-right-4:before {
    content: "";
    right: -1rem
}

.before\:-top-4:before {
    content: "";
    top: -1rem
}

.before\:block:before {
    content: "";
    display: block
}

.before\:h-14:before {
    content: "";
    height: 3.5rem
}

.before\:h-\[11px\]:before {
    content: "";
    height: 11px
}

.before\:w-14:before {
    content: "";
    width: 3.5rem
}

.before\:w-\[11px\]:before {
    content: "";
    width: 11px
}

.before\:rounded-full:before {
    content: "";
    border-radius: 9999px
}

.room__header {
    position: fixed;
    width: 100%;
    top: 0;
    padding-right: 2rem;
    background: transparent;
    border: none;
}

.transparent {
    background-color: transparent !important;
    backdrop-filter: blur(1rem);
}

.room__header * {
    color: var(--bs-white);
}

.dot {
    transform: scale(.8);
}

.none {
    display: none;
}

.ring {
    transform: scale(0);
    opacity: 0;
}

.dot-one {
    left: 50%;
    top: 57%;
}

.dot-two {
    left: 40%;
    top: 60%;
}

.dot-three {
    left: 29%;
    top: 65%;
}

.dot-four {
    left: 73%;
    top: 54%;
}

.dot-five {
    left: 95%;
    top: 68%;
}

.dot-six {
    left: 92.2%;
    top: 40%;
}

@media (max-width: 1239.98px) {
    .dot-one {
        left: 50%;
        top: 68%;
    }

    .dot-two {
        left: 40%;
        top: 73%;
    }

    .dot-three {
        left: 29%;
        top: 75%;
    }

    .dot-four {
        left: 73%;
        top: 67%;
    }

    .dot-five {
        left: 95%;
        top: 80%;
    }

    .dot-six {
        left: 92.2%;
        top: 83%;
    }
}

@media (max-width: 1100px) {
    .dot-one {
        left: 50%;
        top: 68%;
    }

    .dot-two {
        left: 40%;
        top: 67%;
    }

    .dot-three {
        left: 29%;
        top: 75%;
    }

    .dot-four {
        left: 73%;
        top: 63%;
    }

    .dot-five {
        left: 95%;
        top: 75%;
    }

    .dot-six {
        left: 92.2%;
        top: 48%;
    }
}

@media (pointer: coarse) {
    .room-wrapper {
        overflow: auto;
        scrollbar-width: none;
        direction: rtl;
        text-align: left;
    }
}