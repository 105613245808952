main#home * {
  font-family: "Tertius Sans Normal", Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: var(--dark);
}

.section {
  position: relative;
}

.section-home-header {
  overflow: hidden;
  padding: 0;
}

.styled-video.overlay {
  object-fit: cover;
}

.styled-video {
  display: flex;
  flex-direction: column;
  position: relative;
}

video.overlay {
  object-fit: cover;
}

.section-home-header .overlay-dark-gradient {
  background: radial-gradient(circle at 50% 65%, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.7) 90%);
  transform: scale(2, 1);
}

#home h2 {
  font-family: "Tertius Sans Bold", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: clamp(1rem, -2.3333rem + 11.1111vw, 3rem);
  color: var(--white);
}

.span-txt {
  overflow: hidden;
}

#hero-section h2 .char {
  font-family: "Tertius Sans Italic", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: clamp(1rem, -2.3333rem + 11.1111vw, 3rem);
  color: #fffafa;
}

#hero-section h2 .word {
  margin: 0 1.25rem;
  /* 20px */
}

#albums,
#testimonials {
  padding: 0 1rem;
  background: var(--light-beige);
}

#home h2 .char {
  color: var(--white);
  font-family: "Tertius Sans Bold", Helvetica, Arial, sans-serif;
  font-size: clamp(1rem, 1rem + 6.8966vw, 5rem);
  letter-spacing: 1rem;
}

#home .font {
  color: var(--white);
  letter-spacing: 0.1875rem;
  font-family: "Tertius Sans Normal", Helvetica, Arial, sans-serif;
  font-size: clamp(1rem, 1rem + 6.8966vw, 1.3rem);
  margin-bottom: 2rem;
}

#albums .size {
  font-size: clamp(1rem, 1rem + 6.8966vw, 1.3rem);
  display: inline-block;
  margin-bottom: 0.5rem;
}

#albums .head ul {
  border-bottom: solid 1px var(--white);
  cursor: pointer;
  margin-bottom: 1rem;
}

#albums .head ul li {
  padding: 1rem;
}

.album-txt-wrapper {
  overflow: hidden;
}

.wvc-column-container {
  z-index: 0;
  position: relative;
  display: flex;
  width: 100%;
}

.wvc-row,
.wvc-row-inner-content,
.wvc-column-inner {
  display: flex;
  align-items: center;
  width: 100%;
}

.wvc-column-inner {
  width: 100%;
  position: relative;
  z-index: 5;
}

.wvc-column-wrapper {
  width: 100%;
  /* margin: 0 auto; */
}

.wvc-element {
  margin-bottom: 2.1875rem;
}

.wvc-album-disc-align-left {
  margin-left: 0;
}

.wvc-album-disc {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

.wvc-album-disc-link-mask {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 14;
}

.wvc-album-disc-cover-container {
  margin: 0 32% 0.625rem 0;
  /* 10px */
  position: absolute;
  z-index: 0;
}

.animated.wvc-album-disc-reveal {
  transform: translate3d(2.5rem, 0, 0) rotate(-42deg);
  /* 40px */
}

.wvc-album-disc-cd .wvc-album-disc-disc-container {
  position: relative;
  top: 1.25rem;
  left: 14rem;
  width: 28rem;
  height: 28rem;
  border-radius: 50%;
  box-shadow: inset 0 0 0.25rem 0.0625rem #ffffffa8,
    0 0 0 0.3125rem #ffffff38,
    0 0 0 0.1875rem #ffffffe0;
}

.wvc-album-disc-disc-container {
  position: absolute;
  transition: all 0.7s cubic-bezier(0, 0.45, 0.54, 0.99);
  transform: translate3d(0, 0, 0) rotate(0);
  border-radius: 50%;
  overflow: hidden;
}

.wvc-album-disc-rotate-hover-stop .wvc-album-disc-disc-inner {
  animation: disc_spin 3.5s linear infinite;
}

.wvc-album-disc-disc-inner {
  width: 100%;
  height: 100%;
}

.wvc-album-disc-disc-img {
  backface-visibility: hidden;
  height: 100%;
  width: 100%;
  vertical-align: middle;
  border-radius: 50%;
}

.wvc-album-disc-cover-img {
  width: 80%;
}

#home img {
  max-width: 100%;
}

.wvc-album-disc-disc-hole {
  z-index: 5;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  height: 2.03125rem;
  width: 2.03125rem;
  box-shadow: 0 0 0 0.125rem rgba(255, 255, 255, 0.99);
  z-index: 2;
  background: rgba(0, 0, 0, 0.35);
  border-radius: 50%;
}

.wvc-album-disc-cover-inner {
  position: relative;
  z-index: 0;
  overflow: hidden;
}

@keyframes disc_spin {
  100% {
    transform: rotate(360deg);
  }
}

.head li.active {
  background-color: var(--dark-brown);
  color: var(--light-beige) !important;
}

.imageContainer {
  position: relative;
}

#home .swiper {
  width: 100%;
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
}

#home .swiper-slide {
  background-position: center;
  background-size: cover;
  width: 38rem;
  height: 100%;
}

#home .swiper-slide img {
  display: block;
  width: 100%;
}

.tes-warpper {
  background-color: var(--dark-brown);
  color: var(--bs-white) !important;
  border-radius: 1.5rem;
  padding: 2rem;
  height: 15rem;
}

.tes-avatar {
  background-color: var(--coral);
  color: var(--bs-white);
  border-radius: 50%;
  height: 2.9375rem;
  width: 2.9375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
}

.tes-customer-name {
  font-size: 1.5rem;
  font-weight: 700;
  padding-bottom: .2rem;
}

.tes-customer-role {
  font-size: .8rem;
  font-weight: 400;
  padding-bottom: .5rem;
}

.tes-svg {
  width: 3.75rem;
  color: #fff;
}

.tes-svg svg {
  fill: var(--bs-white);
}

.tes-customer-description {
  font-size: .9rem !important;
  font-weight: 300;
}

@media (max-width: 1396.36px) {
  .wvc-album-disc-cd .wvc-album-disc-disc-container {
    width: 24rem;
    height: 24rem;
  }
}

@media (max-width: 1228.80px) {
  .wvc-album-disc-cd .wvc-album-disc-disc-container {
    left: 10rem;
    width: 21rem;
    height: 21rem;
  }
}

@media (max-width: 991.98px) {
  .two a {
    height: 12.559375rem !important;
  }

  #home .swiper-slide {
    width: 100%;
    height: 100%;
  }

  .wvc-album-disc-cd .wvc-album-disc-disc-container {
    top: 5rem;
    left: .5rem;
    width: 22rem;
    height: 22rem;
  }

  .wvc-album-disc-cover-img {
    width: 100%;
  }
}

@media (max-width: 374.98px) {
  .wvc-album-disc-cd .wvc-album-disc-disc-container {
    top: 5rem;
    left: .5rem;
    width: 20rem;
    height: 20rem;
  }
}