:root {
  --light-beige: #fff1da;
  --dark-bluey: #363b4e;
  --coral: #e57962;
  --dark-beige: #e5c5a5;
  --light-bluey: #6370a1;
  --middle-beige: #f9eee6;
  --white: #000;
  --black: #00000033;
  --dark-brown: #6e3d3d;
  --swiper-theme-color: #6e3d3d;
}

html {
  font-size: 16px;
}

html.lenis,
html.lenis body {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

body {
  background: var(--light-beige);
  overflow-x: hidden;
}

::-webkit-scrollbar {
  background: var(--light-beige);
  width: 0.7rem;
}

::-webkit-scrollbar-thumb {
  background: var(--dark-beige);
  border-radius: 5rem;
}

@font-face {
  font-family: Tertius Sans Bold;
  src: url("assets/fonts/Geotica-Three/Geotica-Three-W03-Open.ttf");
}

@font-face {
  font-family: Tertius Sans Normal;
  src: url("assets/fonts/GrandCru-LightS/GrandCru-LightS.otf");
}

@font-face {
  font-family: Tertius Sans Italic;
  src: url("assets/fonts/Aloja-Extended/Aloja-Extended.ttf");
}

@media (max-width: 991.98px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 576.98px) {
  html {
    font-size: 12px;
  }
}