.porduct-card {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  
  .product-img {
    width: 100%;
    cursor: pointer;
  }
  
  .product-title {
    font-size: 1rem !important;
    line-height: 1.5rem;
  }
  
  .product-price {
    font-size: 1rem !important;
    margin-bottom: 1rem;
  }
  
  .plus-icon {
    width: 20px;
    height: 20px;
    border: 1px solid var(--white);
    border-radius: 50%;
    background-color: var(--light-beige);
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .plus-icon:hover {
    color: var(--light-beige) !important;
    background-color: var(--white);
  }
  