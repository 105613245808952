#contact {
  padding: 5rem 1rem;
  background: var(--light-beige);
  color: var(--white) !important;
}

#contact * {
  color: var(--white);
}

button.btn-send {
  background: var(--white);
  color: var(--light-beige) !important;
  padding: 1rem;
  border-radius: 1rem;
  margin: 1rem 0;
  width: 100%;
}

#contact h2 {
  font-family: "Tertius Sans Bold", Helvetica, Arial, sans-serif;
  font-size: clamp(1rem, 1rem + 6.8966vw, 3rem);
  color: var(--white);
  font-weight: 600;
}

#contact .p-txt {
  font-size: clamp(1rem, 1rem + 6.8966vw, 1.5rem);
  font-family: "Tertius Sans Normal", Helvetica, Arial, sans-serif;
  color: var(--white);
  font-weight: 600;
}

.contact-txt-wrapper {
  overflow: hidden;
}

.p-txt-container {
  overflow: hidden;
}

.contact-txt {
  font-family: "Tertius Sans Normal", Arial, Helvetica, sans-serif;
}

.form-control:focus {
  border-color: var(--coral);
  box-shadow: 0 0 0 0.25rem rgba(219, 121, 98, 0.25);
}
