#youtube {
  padding: 3rem 0;
}

#youtube h2 {
  font-family: "Tertius Sans Bold", Helvetica, Arial, sans-serif;
  font-size: clamp(1.5rem, 1rem + 6.8966vw, 4rem);
  color: var(--white);
  font-weight: 600;
}

.yt-videos {
  width: 100%;
  height: 25rem;
}

.yt-title {
  font-size: clamp(1rem, 1rem + 6.8966vw, 2rem);
  font-family: "Tertius Sans Normal", Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: var(--dark-brown);
}

.yt-description {
  font-size: clamp(1rem, 1rem + 6.8966vw, 1rem);
  font-family: "Tertius Sans Normal", Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: var(--dark-brown);
}
