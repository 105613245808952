#gallery h2 {
  font-family: "Tertius Sans Bold", Helvetica, Arial, sans-serif;
  font-size: clamp(1rem, 1rem + 6.8966vw, 3rem);
  color: var(--white);
  font-weight: 600;
}

#gallery h3 {
  font-family: "Tertius Sans Italic", Helvetica, Arial, sans-serif;
  font-size: clamp(1rem, 1rem + 6.8966vw, 4rem);
  color: var(--white);
}

#gallery p {
  font-family: "Tertius Sans Normal", Helvetica, Arial, sans-serif;
  color: var(--white);
  font-weight: 600;
  text-align: center;
}

.lg-react-element {
  column-count: 3;
  column-gap: 10px;
}

#gallery img {
  max-width: 100%;
  display: block;
  padding: 5px 0px;
  border-radius: 20px;
  transition: transform 0.2s;
}

.line {
  overflow: hidden;
}