.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 500;
  pointer-events: none;
}

.loading-container .loading-screen {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--light-beige);
}

.loading-logo {
  width: 12em;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(0%) rotate(0.001deg);
}

.loading-logo>* {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;
  position: relative;
  transform: translateY(0%) rotate(0.001deg);
}

.loading-logo svg {
  width: 100%;
}

.loading-logo h2 {
  font-family: "Tertius Sans Normal", Helvetica, Arial, sans-serif;
  font-size: clamp(1rem, -2.3333rem + 11.1111vw, 2rem);
  filter: drop-shadow(0px 4px 3px #6e3d3d4f);
  color: var(--dark-beige);
  font-weight: 700;
}