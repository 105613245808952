.cart-container * {
  font-family: "Tertius Sans Normal", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.5rem;
  color: var(--white);
}

.cart-container {
  --tw-border-opacity: 1;
  border-left: solid;
  border-left-color: var(--dark-brown);
  border-left-width: 1px;
  width: 40%;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-duration: 0.7s;
  transition-property: all;
  --tw-shadow-color: var(--dark-brown);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  --tw-shadow: var(--tw-shadow-colored);
  --tw-shadow: 0 25px 50px -12px #6e3d3d50;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),
    var(--tw-ring-shadow, 0 0 var(--white)), var(--tw-shadow);
  color: var(--white);
  padding-top: 10px;
  padding-bottom: 5rem;
  padding: 1.5rem;
  background: var(--light-beige);
  max-width: 24rem;
  height: 100%;
  z-index: 100;
  top: 0;
  position: fixed;
}

.scrollbar-thin::-webkit-scrollbar {
  width: 0.5rem;
}

.right-0 {
  right: 0;
}

.right-full {
  right: -100%;
}

.grid {
  display: grid;
}

.h-18 {
  height: 72px;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.text-neutral-300 {
  --tw-text-opacity: 1;
  color: #d4d4d4;
  color: rgba(212, 212, 212, var(--tw-text-opacity));
}

.flex-column li {
  transition-duration: 0.5s;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 1;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.cart-img {
  aspect-ratio: 220 / 220;
  -o-object-position: center;
  object-position: center;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}

.gap-2 {
  gap: 0.5rem;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
  max-width: 65ch;
  font-weight: 600;
  font-size: 1rem;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.btn-decrease {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
  height: 7.5rem;
  margin-top: -0.125rem;
  color: var(--white);
  font-weight: 600;
}

.btn-increase {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
  height: 7.5rem;
  margin-top: -0.125rem;
  color: var(--white);
  font-weight: 600;
}

.cart-value {
  width: 33.333333%;
  font-weight: 600;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  --tw-text-opacity: 1;
  color: var(--white);
}

.font-medium {
  font-weight: 500;
  padding: 1.5rem;
}

.btn-checkout {
  transition-duration: 0.2s;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 1.5rem;
  cursor: pointer;
  background: var(--white);
  color: var(--light-beige);
}

.btn-checkout:hover {
  background-color: var(--dark-brown);
  color: var(--light-beige);
}

.btn-checkout::after {
  content: "";
  background-color: var(--light-beige);
  border-radius: 50%;
  height: 0.75rem;
  width: 0.75rem;
  display: block;
}

@media (max-width: 768px) {
  .cart-container {
    width: 100%;
  }
}
