.albums-container * {
  font-family: "Tertius Sans Normal", Helvetica, Arial, sans-serif;
  font-weight: 700;
  line-height: 1.5rem;
  color: var(--white);
}

.albums-container {
  --tw-border-opacity: 1;
  border-right: solid;
  border-right-color: var(--dark-brown);
  border-right-width: 1px;
  width: 40%;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-duration: 0.7s;
  transition-property: all;
  --tw-shadow-color: var(--dark-brown);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  --tw-shadow: var(--tw-shadow-colored);
  --tw-shadow: 0 25px 50px -12px #6e3d3d50;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),
    var(--tw-ring-shadow, 0 0 var(--white)), var(--tw-shadow);
  color: var(--white);
  padding-top: 10px;
  padding-bottom: 5rem;
  padding: 1.5rem;
  background: var(--light-beige);
  max-width: 24rem;
  height: 100%;
  z-index: 100;
  top: 0;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.room__album {
  background-color: transparent !important;
  -webkit-backdrop-filter: blur(1rem);
  backdrop-filter: blur(1rem);
  border-right-color: var(--white);
}

.left-0 {
  left: 0;
}

.left-full {
  left: -100%;
}

.albums-container h3 {
  font-family: "Tertius Sans Bold", Helvetica, Arial, sans-serif;
}

.albums-container h5 {
  position: absolute;
  font-size: 16px;
  bottom: 1rem;
  right: 1rem;
  cursor: pointer;
}

.albums-container .divider {
  width: 100%;
  height: 1px;
  background: var(--white);
}

.albums-container ul {
  margin-top: 5rem;
}

.albums-container li {
  margin-top: 0.5rem;
  font-size: 1.25rem;
  cursor: pointer;
}

.albums-container li a {
  font-size: 1.25rem;
}